/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 4, 2021 */

@font-face {
	font-family: "rock_shieldregular";
	src: url("/fonts/rock_shield-webfont.woff2") format("woff2"), url("/fonts/rock_shield-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

*,
*::after,
*::before {
  /* box-sizing by default is not inherited, so force it to be inherited */
  /* i.e. all child elements with the box-sizing property will now inherit from this */
  box-sizing: inherit;
}

body {
	box-sizing: border-box;
}